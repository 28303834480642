<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-1 opacity_card">
              <CCardBody>
                <CForm>
                  <div class="float-right cont_logo">
                    <!-- <img  class="float-center imgresp" src="../../../public/template/logo_full_devs.png" width="60%" alt=""> -->
                  </div>
                  <h1 class="cont_logo">Ingreso al sistema</h1>
                  <p class="text-muted">&nbsp;</p>
                  <CInput
                    placeholder="Usuario"
                    autocomplete="Digite Dni o correo "
                    v-model="login.login"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Contraseña"
                    type="password"
                    autocomplete="curent-password"
                    v-model="login.password"
                    @keypress.enter="mtd_login"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" @click="mtd_login"
                        >Entrar</CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right">
                      <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton> -->
                    </CCol>
                  </CRow>
                </CForm>
                <CAlert
                  :show.sync="alert.dismissCountDown"
                  closeButton
                  :color="alert.color"
                >
                  {{ alert.message }}
                  <CProgress
                    :color="alert.color"
                    :max="alert.dismissSecs"
                    :value="alert.dismissCountDown"
                    :size="'xs'"
                  />
                </CAlert>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      url: "",
      login: {
        login: "",
        password: "",
      },
      alert: {
        dismissSecs: 3,
        dismissCountDown: 0,
        color: "info",
        message: "",
      },
    };
  },
  created() {
    this.url = this.$store.getters.get__url;
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_login: function () {
      if (this.login.login != "") {
        if (this.login.password != "") {
          this.post({
            url: this.url + "/user/login",
            params: this.login,
          })
            .then((response) => {
              if (response.state == 200) {
                this.$store.commit("mt_set_auth", response.user);
                this.$router.push({ path: "home" });
              } else {
                alert("error");
              }
            })
            .catch((errors) => {
              // this.errorsBackend = errors;
              // this.$emit("error", this.event);
            });
        } else {
          this.alert.color = "warning";
          this.alert.dismissCountDown = 3;
          this.alert.message = "Digite contraseña";
        }
      } else {
        this.alert.color = "warning";
        this.alert.dismissCountDown = 3;
        this.alert.message = "Digite usuario";
      }
    },
    /** alert */
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showDismissibleAlerts() {
      ["alert1", "alert2"].forEach((alert) => (this[alert] = true));
    },
  },
};
</script>
<style scoped>
/* .background-image {
      background-image: url('../../../public/imagen_login.jpeg') ;
      height: 50%;
      background-size: cover;

    }
  .back_image {
    background-image: url('../../../public/background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color:rgba(255, 255, 255, 0.6);
  }
  .cont_logo{
    width: 100%;
    font-size: 24pt;
    text-align: center;
  }
  @media (max-width: 550px) {
    .back_image{
      margin: 0;
      background-image: url('../../../public/mobile.jpg') ;
   
      background-size: cover;
    }
    .opacity_card{
      background-color: rgba(255, 255, 255, 0.9);
    }
    .cont_logo{
      width: 100%;
      font-size: 24pt;
      text-align: center;
    }
    .cont_logo img{
      width: 100% !important;
    }
  } */
.alert {
  position: fixed;
  top: 100px;
  left: 1050px;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}
</style>
